<template>
  <div>
    <div class="subscription-card">
      <md-card>
        <md-card-header>
          <md-card-header-text>
            <div class="md-title">{{ subscriptionName }}</div>
          </md-card-header-text>
        </md-card-header>
        <div v-if="activeSubscription">
          <md-card-content class="subscription-content">
            <img :src="Sub1Logo" class="sub-logo" />
            <div>{{ activeSubscriptionTimeLeftInMinutes }}/{{ subscriptionTimeInHours * 60 }} {{ $t("subscriptions.time_left") }}</div>
            <div>
              {{ activeSubscriptionStart }}
            </div>
            <div>
              {{ activeSubscriptionEnd }}
            </div>
            <div>
              Status:
              {{
                subscriptionCanceled
                  ? $t("subscriptions.cancelled")
                  : $t("subscriptions.active")
              }}
            </div>
          </md-card-content>
          <md-card-actions style="margin-bottom: 20px">
            <md-button
              class="md-primary md-raised"
              @click="subscriptionCanceled ? reactivate() : cancel()"
            >
              {{
                subscriptionCanceled
                  ? $t("subscriptions.resub")
                  : $t("subscriptions.cancel")
              }}
            </md-button>
          </md-card-actions>
        </div>
        <div v-else>
          <md-card-content class="subscription-content">
            <img :src="Sub1Logo" class="sub-logo" />
            <div>
              <p style="color: #757575">
                <md-icon>percent</md-icon><b>2024 SPECIAL PROMOTION</b
                ><md-icon>percent</md-icon>
              </p>
              <!-- Hardcoded for basic promo TODO: replace -->
              <p>
                {{ this.subscriptionPriceInCHF }}
                {{ $t("subscriptions.sub_price") }}
              </p>
              <p>
                {{ this.subscriptionTimeInHours }}h
                {{ $t("subscriptions.sub_time") }}
              </p>
              <p>{{ $t("subscriptions.sub_duration") }}</p>
            </div>
          </md-card-content>
          <md-card-actions>
            <md-button
              class="md-primary md-raised"
              @click="showPayment = true"
              >{{ $t("subscriptions.sub") }}</md-button
            >
          </md-card-actions>
        </div>
      </md-card>
    </div>
    <md-dialog :md-active.sync="showPayment">
      <md-dialog-title>
        <md-button
          class="md-icon-button md-close-button"
          @click="showPayment = false"
        >
          <md-icon>close</md-icon>
        </md-button>
      </md-dialog-title>
      <md-dialog-content>
        <SubscriptionPayment :planId="planId" @close="onPaymentClose" />
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import subscriptionMixin from "../../mixins/subscriptionMixin.js";
import SubscriptionPayment from "./SubscriptionPayment.vue";
import Sub1Logo from "../../assets/Sub_1.svg";

export default {
  name: "SubscriptionActive",
  components: {
    SubscriptionPayment,
  },
  mixins: [subscriptionMixin],
  props: ["planId"],
  data() {
    return {
      showPayment: false,
      Sub1Logo,
    };
  },
  methods: {
    onPaymentClose() {
      this.$emit("reload");
      this.showPayment = false;
    }
  }
};
</script>

<style scoped>
.subscription-card {
  justify-content: left;
}

.md-card-actions.md-alignment-right {
  justify-content: center;
}

/* Align dialog close button to the right */
.md-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.md-card-actions {
  display: flex;
  justify-content: center;
  padding: 10px;
}

/* Ensure the subscription-card is aligned left */
.subscription-card {
  max-width: 100%;
  margin-left: 0;
  padding: 20px;
  box-sizing: border-box;
}

.subscription-content {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.sub-logo {
  height: 50%;
  width: 50%;
  margin-bottom: 20px;
  justify-content: center;
  align-self: center;
}

.modal-overlay {
  width: 50%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 50%;
  max-width: 500px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .modal-content {
    width: 90vw;
  }
}

@media only screen and (max-width: 350px) {
  .subscription-card {
    width: 90vw;
    height: 80vh;
  }
}

@media only screen and (max-width: 500px) and (min-width: 350px) {
  .subscription-card {
    width: 90vw;
    height: 40vh;
  }
}

@media only screen and (max-width: 700px) and (min-width: 500px) {
  .subscription-card {
    width: 70vw;
    height: 45vh;
  }
}

@media only screen and (max-width: 900px) and (min-width: 700px) {
  .subscription-card {
    width: 45vw;
    height: 50vh;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 900px) {
  .subscription-card {
    width: 450px;
    height: 500px;
  }
}

@media only screen and (min-width: 1100px) {
  .subscription-card {
    width: 450px;
    height: 500px;
  }
}
</style>
